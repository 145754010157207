import dayjs from 'dayjs'

export const formatDate = (isoDate: string): string => dayjs(isoDate).format('DD.MM.YYYY HH:mm');


export const parseLogs = (logs: string[]): { step: string; details: string[] }[] => {
  const parsedLogs: { step: string; details: string[] }[] = [];
  let currentStep: string = '';
  let currentDetails: string[] = [];

  logs.forEach((log) => {
    if (log.startsWith('Step')) {
      if (currentStep) {
        parsedLogs.push({ step: currentStep, details: currentDetails });
      }
      currentStep = log;
      currentDetails = [];
    } else {
      currentDetails.push(log);
    }
  });

  if (currentStep) {
    parsedLogs.push({ step: currentStep, details: currentDetails });
  }

  return parsedLogs;
};

export const mapRequestTimeWindowData = (requestTimeWindows: any[]): any[] => requestTimeWindows.map((item) => {
  const { carts } = item;
  const timeslotsCount = [
    carts.fullReplacements?.items.length || 0,
    carts.main?.items.length || 0,
    carts.partialReplacements?.items.length || 0,
    carts.reducedQty?.items.length || 0,
  ];

  const basketAfterPayment = [
    ...(carts.fullReplacements
      ? [
        {
          type: 'fullReplacements',
          items: carts.fullReplacements.items,
          timeSlots: carts.fullReplacements.timeSlots,
        },
      ]
      : []),
    ...(carts.main ? [{ type: 'main', items: carts.main.items, timeSlots: carts.main.timeSlots }] : []),
    ...(carts.partialReplacements
      ? [
        {
          type: 'partialReplacements',
          items: carts.partialReplacements.items,
          timeSlots: carts.partialReplacements.timeSlots,
        },
      ]
      : []),
    ...(carts.reducedQty
      ? [{ type: 'reducedQty', items: carts.reducedQty.items, timeSlots: carts.reducedQty.timeSlots }]
      : []),
  ];

  return {
    orderDate: formatDate(item.createdAt),
    paymentId: item.id,
    basketId: item.cartId,
    result: item.error ? 'calculation_error' : 'calculation_success',
    addressDelivery: item.deliveryAddress,
    orderAmount: item.originalCartValue,
    subInfo: [
      {
        timeslotsCount,
        basketAfterPayment,
        additionalFeature: item.error ? 'calculation_error' : 'calculation_success',
        errorText: item.error,
        address: item.deliveryAddress,
        fullLog: parseLogs(item.logs),
      },
    ],
  };
});
