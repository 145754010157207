import { TransformedVehicleType } from "@core/interfaces/reference-data";
import { VehicleType } from "@core/interfaces/vehicle-info.interface";

export function transformVehicleType(vehicle: VehicleType): TransformedVehicleType {
    return {
      typeVehicleName: vehicle.name,
      capacity: vehicle.loadCapacity,
      typeBaggage: vehicle.type,
      width: vehicle.width,
      height: vehicle.height,
      length: vehicle.length,
      subInfo: [
        {
          liftingCapacity: vehicle.loadCapacity,
          speed: vehicle.speed,
          cost: vehicle.fuelCost,
        },
      ],
      canUpdate: vehicle.canUpdate,
      canArchive: vehicle.canArchive,
      id: vehicle.id,
      name: '',
    };
  }