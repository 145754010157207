/* eslint-disable */
import { Injectable } from '@angular/core';
import { State, Store, Action, StateContext, Selector } from '@ngxs/store';
import { TimeWindowService } from '@core/services/time-window.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, of, tap, throwError } from 'rxjs';
import { ArchiveTimeWindow, CreateTimeWindow, GetListTimeWindow, UpdateTimeWindow } from '../action/time-window.action';
import { TimeWindow } from '@core/interfaces/time-window.interface';

export class TimeWindowStateModel {
  timeWindows!: Array<TimeWindow>;
  error!: string;
  total!: number | null;
}

@State<TimeWindowStateModel>({
  name: 'TimeWindowState',
  defaults: {
    timeWindows: [],
    error: '',
    total: null,
  },
})

@Injectable()
export class TimeWindowState {
  constructor(
    private timeWindowService: TimeWindowService,
  ) {}

  @Selector()
  static getTimeWindows(state: TimeWindowStateModel): TimeWindow[] {
    return state.timeWindows;
  }

  @Selector()
  static getTimeWindowsTotal(state: TimeWindowStateModel): number | null {
    return state.total;
  }


  @Selector()
  static getError(state: TimeWindowStateModel): string {
    return state.error;
  }

  @Action(GetListTimeWindow)
  public getListTimeWindow(ctx: StateContext<TimeWindowStateModel>, { pagination, filter }: GetListTimeWindow) {
    return this.timeWindowService.getListTimeWindow(filter,pagination).pipe(
      tap((res) => {
        if (res && res.data) {
          ctx.patchState({ timeWindows: res.data.getListTimeWindow.result });
          ctx.patchState({ total: res.data.getListTimeWindow.total });
        }
      }),
    );
  }

  @Action(CreateTimeWindow)
  public createTimeWindow(ctx: StateContext<TimeWindowStateModel>, { createTimeWindowData, dialogRef }: CreateTimeWindow) {
    ctx.patchState({ error:'' });
    return this.timeWindowService.createTimeWindow(createTimeWindowData).pipe(
      tap((res) => {
        if (res && res.data) {
          const newTimeWindow = res.data.createTimeWindow;
          const currentState = ctx.getState();
          dialogRef.close()
          ctx.patchState({
            timeWindows: [...currentState.timeWindows, newTimeWindow],
          });
        }
      }),
      catchError((error) => {
        ctx.patchState({ error: error.message });
        return of(null);
      })
    );
  }

  @Action(UpdateTimeWindow)
  public updateTimeWindow(ctx: StateContext<TimeWindowStateModel>, { updateTimeWindowData, id, dialogRef }: UpdateTimeWindow) {
    ctx.patchState({ error:'' });
    return this.timeWindowService.updateTimeWindow(id ,updateTimeWindowData).pipe(
      tap((res) => {
        if (res && res.data) {
          const updatedTimeWindow = res.data.updateTimeWindow;
          const currentState = ctx.getState();
          const timeWindowIndex = currentState.timeWindows.findIndex((timeWindow) => timeWindow.id === updatedTimeWindow.id);

          if (timeWindowIndex !== -1) {
            const updatedTimeWindows = [...currentState.timeWindows];
            updatedTimeWindows[timeWindowIndex] = updatedTimeWindow;
            dialogRef.close()
            ctx.patchState({ timeWindows: updatedTimeWindows });
          }
        }
      }),
      catchError((error) => {
        ctx.patchState({ error: error.message });
        return of(null);
      })
    );
  }

  @Action(ArchiveTimeWindow)
  public archiveTimeWindow(ctx: StateContext<TimeWindowStateModel>, { id }: ArchiveTimeWindow) {
    return this.timeWindowService.archiveTimeWindow(id).pipe(
      tap((res) => {
        if (res && res.data) {
          const archivedTimeWindowId = res.data.archiveTimeWindow.id;
          const currentState = ctx.getState();
          const updatedTimeWindows = currentState.timeWindows.filter(
            (timeWindow) => timeWindow.id !== archivedTimeWindowId,
          );
          ctx.patchState({ timeWindows: updatedTimeWindows });
        }
      }),
    );
  }
}
