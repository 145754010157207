import { Vehicle, VehicleTransformed } from '@core/interfaces/vehicle-info.interface';

export function transformVehicle(vehicle: Vehicle): VehicleTransformed {
  return {
    typeVehicleName: vehicle.name,
    loadCapacity: vehicle.loadCapacity,
    typeVehicle: vehicle.type.type,
    subInfo: [
      {
        speed: vehicle.speed,
        cost: vehicle.fuelCost,
        fuelConsumption: vehicle.fuelConsumption,
        number: vehicle.number,
      },
    ],
    canUpdate: vehicle.canUpdate,
    canArchive: vehicle.canArchive,
    id: vehicle.id,
    length: vehicle.length,
    width: vehicle.width,
    height: vehicle.height,
    size: `${vehicle.length}x${vehicle.width}x${vehicle.height}`,
    volume: vehicle.volume,
  };
}
