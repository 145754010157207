/* eslint-disable */

import { Injectable } from '@angular/core';
import { VehicleService } from '@core/services/vehicle.service';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { GetListVehicle, AddVehicle, UpdateVehicle, ArchiveVehicle } from '../action/vehicle.action';
import { Vehicle, VehicleTransformed } from '@core/interfaces/vehicle-info.interface';
import { transformVehicle } from '@core/helper/vehicle-helper';

export class VehiclesStateModel {
  vehicles!: Array<Vehicle>;
}

@State<VehiclesStateModel>({
  name: 'VehiclesState',
  defaults: {
    vehicles: [],
  },
})
@Injectable()
export class VehiclesState {
  constructor(private vehicleService: VehicleService) {}

  @Selector()
  static getListVehicles(state: VehiclesStateModel): Vehicle[] {
    return state.vehicles;
  }

  @Selector()
  static getListTransformedVehicles(state: VehiclesStateModel): VehicleTransformed[] {
    return state.vehicles.map(vehicle => transformVehicle(vehicle));
  }

  @Action(GetListVehicle)
  public getListVehicle(ctx: StateContext<VehiclesStateModel>, { filter }:GetListVehicle ) {
    return this.vehicleService.getListVehicle(filter).pipe(
      tap((res) => {
        ctx.patchState({ vehicles: res.data.getListVehicle });
      }),
    );
  }

  @Action(AddVehicle)
  public addVehicle(ctx: StateContext<VehiclesStateModel>, { vehicle }: AddVehicle) {
    const currentState = ctx.getState();

    const updatedVehicles = [...currentState.vehicles, vehicle];
    ctx.patchState({
      vehicles: updatedVehicles,
    });
  }

  @Action(UpdateVehicle)
  public updateVehicle(ctx: StateContext<VehiclesStateModel>, { vehicle }: UpdateVehicle) {
    const currentState = ctx.getState();

    const updatedVehicles = [...currentState.vehicles];
    const vehicleIndex = updatedVehicles.findIndex((v) => v.id === vehicle.id);

    if (vehicleIndex !== -1) {
      updatedVehicles[vehicleIndex] = {...updatedVehicles[vehicleIndex], ...vehicle};
    }

    ctx.patchState({
      vehicles: updatedVehicles,
    });
  }

  @Action(ArchiveVehicle)
  public archiveVehicle(ctx: StateContext<VehiclesStateModel>, { id }: ArchiveVehicle) {
      const currentState = ctx.getState();

      const updatedVehicles = currentState.vehicles.filter(
        (vehicle) => vehicle.id !== id
      );

      ctx.patchState({
        vehicles: updatedVehicles,
      });

  }
}
