import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { FetchPolicy } from '@core/enums/fetch-policy-apollo.enum';
import {
  ArchiveResponseCourierSchedule,
  CourierScheduleData,
  CourierSheduleResponse,
  CourierWorkSchedulesFilter,
} from '@core/interfaces/courier-shedule.interface';
import { ArchiveResponse } from '@core/interfaces/vehicle-info.interface';
import { Apollo, MutationResult, gql } from 'apollo-angular';
import { Observable, tap } from 'rxjs';
import { UpdateCourierWorkSchedulesDto } from '@core/dtos/update-courier-work-schedule.dto';
import { GetOrdersThatDontFitInVehicleDto } from '@core/dtos/get-orders-that-dont-fit-for-route-list.dto';
import { Order } from '@core/interfaces/order.interface';
import { Store } from '@ngxs/store';
import {
  AddCourierSchedule,
  ArchiveCourierSchedules,
  UpdateCourierSchedule,
} from '@core/store/action/courier-work-schedule.action';

@Injectable({
  providedIn: 'root',
})
export class CourierScheduleService {
  constructor(
    private readonly apollo: Apollo,
    private store: Store,
  ) {}

  public getCourierSchedule(
    filter: CourierWorkSchedulesFilter,
  ): Observable<MutationResult<{ getListCourierWorkSchedule: CourierSheduleResponse[] }>> {
    return this.apollo.query<{ getListCourierWorkSchedule: CourierSheduleResponse[] }>({
      query: gql`
        query getListCourierWorkSchedule($filter: GetCourierWorkSchedulesFilter!) {
          getListCourierWorkSchedule(filter: $filter) {
            id
            timeTo
            shiftDate
            status
            archivedAt
            canArchive
            canUpdate
            courierReplacement {
              id
              firstName
              lastName
              name
            }
            reason
            courier {
              id
              firstName
              lastName
              middleName
              role
              name
            }
            timeFrom
            timeTo
            shiftDate
            canArchive
            canUpdate
            vehicle {
              id
              name
              type {
                id
                name
                type
              }
            }
            zone {
              color
              id
              name
            }
          }
        }
      `,
      variables: {
        filter,
      },
      fetchPolicy: FetchPolicy.NoCache,
    });
  }

  public getOrdersThatDontFitForRouteList(
    params: GetOrdersThatDontFitInVehicleDto,
  ): Observable<MutationResult<{ getOrdersThatDoNotFitForRouteList: Order[] }>> {
    return this.apollo.query<{ getOrdersThatDoNotFitForRouteList: Order[] }>({
      query: gql`
        query getOrdersThatDoNotFitForRouteList($params: GetOrdersThatDontFitInVehicleDto!) {
          getOrdersThatDoNotFitForRouteList(data: $params) {
            id
            createdAt
            isValid
          }
        }
      `,
      variables: {
        params,
      },
      fetchPolicy: FetchPolicy.NoCache,
    });
  }

  createCourierWorkSchedule(
    data: any,
  ): Observable<MutationResult<{ createCourierWorkSchedules: CourierSheduleResponse }>> {
    return this.apollo
      .mutate<{ createCourierWorkSchedules: CourierSheduleResponse }>({
        mutation: gql`
          mutation createCourierWorkSchedules($data: CreateCourierWorkSchedulesDto!) {
            createCourierWorkSchedules(data: $data) {
              id
              shiftDate
              timeFrom
              timeTo
              status
              courier {
                id
                firstName
              }
              vehicle {
                id
                name
              }
              zone {
                id
                name
              }
            }
          }
        `,
        variables: {
          data,
        },
      })
      .pipe(
        tap((res) => {
          if (res && res.data) {
            this.store.dispatch(new AddCourierSchedule(res.data.createCourierWorkSchedules));
          }
        }),
      );
  }

   public updateCourierWorkSchedule(
    id: number,
    data: UpdateCourierWorkSchedulesDto,
  ): Observable<MutationResult<{ updateWorkSchedule: CourierSheduleResponse }>> {
    return this.apollo
      .mutate<{ updateWorkSchedule: CourierSheduleResponse }>({
        mutation: gql`
          mutation updateWorkSchedule($id: Int!, $data: UpdateCourierWorkSchedulesDto!) {
            updateWorkSchedule(id: $id, data: $data) {
              id
              shiftDate
              timeFrom
              timeTo
              status
              reason
              courierReplacement {
                id
                name
              }
              courier {
                id
                name
              }
              vehicle {
                id
                name
              }
              zone {
                id
                name
              }
            }
          }
        `,
        variables: {
          id,
          data,
        },
      })
      .pipe(
        tap((res) => {
          if (res && res.data) {
            this.store.dispatch(new UpdateCourierSchedule(res.data.updateWorkSchedule));
          }
        }),
      );
  }

  public archiveCourierWorkSchedule(
    id: number,
  ): Observable<FetchResult<{ archiveCourierWorkSchedule: ArchiveResponseCourierSchedule }>> {
    return this.apollo
      .mutate<{ archiveCourierWorkSchedule: ArchiveResponseCourierSchedule }>({
        mutation: gql`
          mutation archiveCourierWorkSchedule($id: Float!) {
            archiveCourierWorkSchedule(id: $id) {
              id
              archivedAt
            }
          }
        `,
        variables: {
          id,
        },
      })
      .pipe(
        tap((res) => {
          if (res && res.data) {
            this.store.dispatch(
              new ArchiveCourierSchedules(
                res.data.archiveCourierWorkSchedule.id,
                res.data.archiveCourierWorkSchedule.archivedAt,
              ),
            );
          }
        }),
      );
  }
}
