/* eslint-disable */
import { MatDialogRef } from '@angular/material/dialog';
import { AddTimeWindowDialogComponent } from '@app/modules/time-window-page/add-time-window-dialog/add-time-window-dialog.component';
import { LocationFilter } from '@core/interfaces/location-filter.interface';
import { CreateTimeWindowDto, UpdateTimeWindowDTO } from '@core/interfaces/time-window.interface';
import { OffsetPagination } from '@core/interfaces/zone.interface';

export class GetListTimeWindow {
  static readonly type = '[TimeWindow] Get List Time Window';
  constructor(
    public pagination?: OffsetPagination,
    public filter?: LocationFilter,
  ) {}
}

export class CreateTimeWindow {
  static readonly type = '[TimeWindow] Create Time Window';
  constructor(public createTimeWindowData: CreateTimeWindowDto,
  public dialogRef: MatDialogRef<AddTimeWindowDialogComponent>,
  ) {}
}

export class UpdateTimeWindow {
  static readonly type = '[TimeWindow] Update Time Window';
  constructor(
    public updateTimeWindowData: UpdateTimeWindowDTO,
    public id: number,
    public dialogRef: MatDialogRef<AddTimeWindowDialogComponent>,
  ) {}
}

export class ArchiveTimeWindow {
  static readonly type = '[TimeWindow] Archive Time Window';
  constructor(public id: number) {}
}
