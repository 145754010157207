import { LocationFilter } from '@core/interfaces/time-window.interface';
import { OffsetPagination } from '@core/interfaces/zone.interface';
import { Pharmacy } from "@core/interfaces/pharmacy.interface";
import { TranslateService } from "@ngx-translate/core";

export class GetListPharmacy {
  static readonly type = '[GetListPharmacy] Get List Pharmacy';
  constructor(
    public translateService: TranslateService,
    public pagination?: OffsetPagination,
    public locationFilter?: LocationFilter,
  ) {}
}

export class UpdatePharmacies {
  static readonly type = '[UpdatePharmacies] Update Pharmacies';
  constructor(
    public translateService: TranslateService,
    public pharmacies: Pharmacy[],
  ) {}
}
