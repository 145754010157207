import {
  CourierSheduleResponse,
  CourierScheduleData,
  GroupedSchedules,
} from '@core/interfaces/courier-shedule.interface';

export function getGroupedSchedules(data: CourierSheduleResponse[]): CourierScheduleData[] {
  const groupedSchedules = data.reduce((acc: GroupedSchedules, schedule: CourierSheduleResponse) => {
    const courierId = schedule.courier.id;

    if (!acc[courierId]) {
      acc[courierId] = {
        courier: schedule.courier,
        schedulesByDate: {},
      };
    }

    const { shiftDate } = schedule;

    if (!acc[courierId].schedulesByDate[shiftDate]) {
      acc[courierId].schedulesByDate[shiftDate] = [];
    }

    acc[courierId].schedulesByDate[shiftDate].push(schedule);

    return acc;
  }, {});

  return Object.values(groupedSchedules).map((group: any) => ({
    courier: group.courier,
    schedulesByDate: Object.entries(group.schedulesByDate).map(([date, schedules]: any) => ({
      date,
      schedules,
    })),
  }));
}

export function getUniqueDates(couriersScheduleData: CourierScheduleData[]): string[] {
  const dates = new Set<string>();
  couriersScheduleData.forEach((courier: CourierScheduleData) => {
    courier.schedulesByDate.forEach((scheduleByDate: { date: string }) => {
      dates.add(scheduleByDate.date);
    });
  });
  return Array.from(dates).sort();
}

export function prepareScheduleMatrix(
  couriersScheduleData: CourierScheduleData[],
  arrayDate: string[],
): CourierSheduleResponse[][][] {
  return couriersScheduleData.map((courierData: CourierScheduleData) =>
    arrayDate.map((date) => {
      const scheduleData = courierData.schedulesByDate.find(
        (schedule: { date: string; schedules: CourierSheduleResponse[] }) => schedule.date === date,
      );
      return scheduleData ? scheduleData.schedules : [];
    }),
  );
}
