/* eslint-disable */

import {
  CourierScheduleData,
  CourierSheduleResponse,
  CourierWorkSchedulesFilter,
} from '@core/interfaces/courier-shedule.interface';

export class GetCourierSchedules {
  static readonly type = '[GetCourierSchedules] Get Courier Schedules';
  constructor(public courierWorkSchedulesFilter: CourierWorkSchedulesFilter) {}
}

export class AddCourierSchedule {
  static readonly type = '[AddCourierSchedule] Add Courier Schedule';
  constructor(public courierScheduleData: CourierSheduleResponse) {}
}

export class UpdateCourierSchedule {
  static readonly type = '[UpdateCourierSchedule] Update Courier Schedule';
  constructor(public courierScheduleData: CourierSheduleResponse) {}
}

export class ArchiveCourierSchedules {
  static readonly type = '[ArchiveCourierSchedules] Archive Courier Schedules';
  constructor(
    public id: string,
    public archivedAt: string,
  ) {}
}
