<div class="wrapper">
  <div class="container">
    <div class="header">
      <img src="/assets/icons/shared-icons/logo.svg" alt="logo">
    </div>
    
    <div class="auth-wrapper">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmitForm()">
        <p class="title">{{ 'change_password_header' | translate }}</p>
        <div class="auth-container">
          <div class="password-container">
            <p class="input-info">{{'change_password_title' | translate }}</p>
            <mat-form-field appPasswordViasability class="password-form-field">
              <input matInput formControlName="password" class="password-input" type="text" placeholder="{{'change_password_placeholder' | translate }}">
            </mat-form-field>        
          </div>
          <div class="password-container">
            <p class="input-info">{{ 'change_password_confirm_title' | translate }}</p>
            <mat-form-field appPasswordViasability class="password-form-field">
              <input matInput formControlName="confirmPassword" class="password-input" type="text" placeholder="{{'change_password_confirm_placeholder' | translate }}">
            </mat-form-field>        
          </div>
          @if (loginForm.errors?.["passwordMismatch"]) {
            <mat-error>
              {{'change_password_error' | translate }}
             </mat-error>
          }
        </div>
      <button [disabled]="!loginForm.valid" class="submit" mat-button >{{('change_password_submit')}}</button>
    </form>
      <a [routerLink]="'/auth'" class="back">
        {{ 'change_password_return_title' | translate}}
      </a>
    </div>
  </div>
</div>
