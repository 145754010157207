<div class="wrapper">
  <div class="container">
    <div class="header">
      <img src="/assets/icons/shared-icons/logo.svg" alt="logo" />
    </div>
    <div class="auth-wrapper">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmitForm()">
        <p class="title">{{ 'auth_title_header' | translate }}</p>
        <div class="auth-container">
          <div class="number-container">
            <p class="input-info">{{ 'auth_title_number' | translate  }}</p>
            <mat-form-field class="number-form-field">
              <input
                matInput
                formControlName="phone"
                prefix="+38"
                mask="(000) 000 00 00"
                placeholder="+38(000) 000 00 00"
                class="login-input"
                type="text"
              />
            </mat-form-field>
            @if (loginForm.get('phone')?.hasError('required') && loginForm.get('phone')?.dirty) {
              <mat-error> Phone number is required </mat-error>
            }
            @if (loginForm.get('phone')?.hasError('invalidPhoneNumber') && loginForm.get('phone')?.dirty) {
              <mat-error> Invalid phone number pattern </mat-error>
            }
          </div>
          <div class="password-container">
            <p class="input-info">{{ 'auth_title_password' | translate }}</p>
            <mat-form-field appPasswordViasability class="password-form-field">
              <input matInput formControlName="password" class="password-input" type="text" placeholder="{{'auth_title_password_placeholder' | translate}}" />
            </mat-form-field>
            @if (loginForm.get('password')?.hasError('required') && loginForm.get('phone')?.dirty) {
              <mat-error> Password is required </mat-error>
            }
          </div>
          @if (errorMessage) {
            <mat-error>
              {{ errorMessage }}
            </mat-error>
          }
          <div class="action-container">
            <div class="checkbox-wrapper">
              <mat-checkbox>{{ 'auth_remember_me' | translate }}</mat-checkbox>
            </div>
            <a [routerLink]="'/auth/forgot-password'" class="forgot-password-link">
              {{ 'auth_title_forgot_password' | translate }}
            </a>
          </div>
        </div>
        <button class="submit" [disabled]="!loginForm.valid" mat-button>{{ 'auth_submit' | translate}}</button>
      </form>
    </div>
  </div>
</div>
