import { Injectable } from '@angular/core';
import { State,  Action, StateContext, Selector } from '@ngxs/store';
import { AdminData } from "@core/interfaces/auth.interface";
import { SetAdminData } from "@core/store/action/admin-data.action";

export class AdminDataStateModel {
    adminData!: AdminData | null;
}

@State<AdminDataStateModel>({
    name: 'AdminDataState',
    defaults: {
        adminData: null
    },
})

@Injectable()
export class AdminDataState {
    constructor() {}

    @Selector()
    static getAdminData(state: AdminDataStateModel): AdminData | null {
        return state.adminData;
    }

    @Action(SetAdminData)
    public setAdminData(ctx: StateContext<AdminDataStateModel>, { adminData }: SetAdminData) {
        ctx.patchState({ adminData });
    }
}
