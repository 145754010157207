/* eslint-disable */

import { RequestTimeWindowFilterDto } from "@core/interfaces/requests-time-windows.interface";
import { OffsetPagination } from "@core/interfaces/zone.interface";

export class GetListRequestTimeWindow {
    static readonly type = '[RequestTimeWindow] Get List';
    constructor(
      public filter: Partial<RequestTimeWindowFilterDto> = {},
      public pagination?: OffsetPagination,
    ) {}
  }