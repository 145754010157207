import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import localeUk from '@angular/common/locales/uk';
import localeEn from '@angular/common/locales/en';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { NgxsModule } from '@ngxs/store';
import {
  HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient,
} from '@angular/common/http';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { AuthInterceptor } from '@core/interceptors/auth-interceptor';
import { LoaderInterceptor } from '@core/interceptors/loader-interceptor';
import * as Sentry from '@sentry/angular';
import { environment } from '@environments/environment';
import { ZonesState } from '@core/store/state/zone.state';
import { VehiclesState } from '@core/store/state/vehicle.state';
import { ReferencesState } from '@core/store/state/reference.state';
import { TimeWindowState } from '@core/store/state/time-window.state';
import { PharmaciesState } from '@core/store/state/pharmacy.state';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { LocaleService } from '@core/services/locale.service';
import { registerLocaleData } from '@angular/common';
import { ErrorInterceptor } from "@core/interceptors/error-interceptor";
import { RequestTimeWindowState } from '@core/store/state/requests-time-windows.state';
import { CourierSchedulesState } from '@core/store/state/courier-work-schedule.state';
import { CouriersState } from '@core/store/state/courier.state';
import { graphqlProvider } from './graphql.provider';
import { routes } from './app.routes';
import { AdminDataState } from '@core/store/state/admin-data.state';


const maskConfig: Partial<IConfig> = {
  validation: false,
};

export function initializeApp(translateService: TranslateService, LocaleService: LocaleService): () => void {
  return () => {
    const savedLocale = LocaleService.getSavedLocale();
    return firstValueFrom(translateService.use(savedLocale!));
  };
}

registerLocaleData(localeUk, 'uk');
registerLocaleData(localeEn, 'en');

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  const baseUrl = 'https://dms-demo-api.dev2.scrij.com/public/dms/translations';
  return new TranslateHttpLoader(http, `${baseUrl}/`, '?updatedAfter=2024-12-20');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync('animations'),
    provideEnvironmentNgxMask(maskConfig),
    provideHttpClient(),

    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService) => localeService.getSavedLocale(),
      deps: [LocaleService],
    },
    importProvidersFrom(
      NgxsModule.forRoot([ZonesState, CouriersState, PharmaciesState, TimeWindowState, VehiclesState, AdminDataState, ReferencesState ,CourierSchedulesState ,RequestTimeWindowState], {
        developmentMode: !environment.production,
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    graphqlProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [TranslateService, LocaleService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'uk' },
  ],
};
