/* eslint-disable */
import { Injectable } from '@angular/core';
import { State, Store, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs';
import { GetListPharmacy, UpdatePharmacies } from '../action/pharmacy.action';
import { Pharmacy } from '@core/interfaces/pharmacy.interface';
import { PharmacyService } from '@core/services/pharmacy.service';
import { transformPharmacy } from '@core/helper/pharmacy-helper';

export class PharmaciesStateModel {
  pharmacies!: Array<Pharmacy>;
  total!: number | null;
}

@State<PharmaciesStateModel>({
  name: 'PharmaciesState',
  defaults: {
    pharmacies: [],
    total: null,
  },
})

@Injectable()
export class PharmaciesState {
  constructor(
    private store: Store,
    private pharmacyService: PharmacyService,
  ) {}

  @Selector()
  static getPharmacies(state: PharmaciesStateModel): Pharmacy[] {
    return state.pharmacies;
  }

  @Selector()
  static getPharmaciesTotal(state: PharmaciesStateModel): number | null {
    return state.total;
  }

  @Action(GetListPharmacy)
  public getListPharmacy(ctx: StateContext<PharmaciesStateModel>, { translateService, pagination, locationFilter }: GetListPharmacy) {
    return this.pharmacyService.getListPharmacy(locationFilter!, pagination!).pipe(
      tap((res) => {
        ctx.patchState({ total: res.data.getListPharmacy.total });
        const pharmacyList = res.data.getListPharmacy.result.map((pharmacy: Pharmacy) => transformPharmacy(pharmacy, translateService));
        ctx.patchState({ pharmacies: pharmacyList });
      }),
    );
  }

  @Action(UpdatePharmacies)
  public updatePharmacies(ctx: StateContext<PharmaciesStateModel>, { translateService, pharmacies }: UpdatePharmacies) {
    const currentState = ctx.getState();

    const currentPharmacies = [...currentState.pharmacies];
    for (const pharmacy of pharmacies) {
      const pharmacyIndexToUpdate = currentPharmacies.findIndex(p => p.id === pharmacy.id);
      if (pharmacyIndexToUpdate === -1) {
        currentPharmacies.push(transformPharmacy(pharmacy, translateService))
      } else {
        currentPharmacies[pharmacyIndexToUpdate] = transformPharmacy(pharmacy, translateService);
      }
    }
    ctx.patchState({
      pharmacies: currentPharmacies,
    });
  }
}
