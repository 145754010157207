import { Injectable } from '@angular/core';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError, interval, Observable, Subject, takeUntil, tap, throwError,
} from 'rxjs';
import {
  AdminData, ChangePasswordResponse, ForgotPasswordResponse, LoginResponse, LogoutResponse,
} from '@core/interfaces/auth.interface';
import { TokenEnum } from '@core/enums/token.enum';
import { ApolloError } from '@apollo/client/errors';
import { TokenService } from './token.service';
import { Store } from "@ngxs/store";
import { SetAdminData } from "@core/store/action/admin-data.action";

@Injectable({
  providedIn: 'root',
})
export class ApiAuthService {

  private timerValue = 60;

  private intervalValue = 1000;

  private timerValue$ = new BehaviorSubject<number | null>(null);

  private stopTimer$ = new Subject<void>();

  constructor(
    private readonly apollo: Apollo,
    private readonly tokenService: TokenService,
    private readonly router: Router,
    private store: Store,
  ) { }

  private oldPassword: string = '';

  get oldPasswordData(): string {
    return this.oldPassword;
  }

  set oldPasswordData(value: string) {
    this.oldPassword = value;
  }

  public login(phoneNumber: string, password: string): Observable<MutationResult<{ login: LoginResponse }>> {
    return this.apollo.mutate<{ login: LoginResponse }>({
      mutation: gql`
        mutation Login($phoneNumber: String!, $password: String!) {
          login(data: { phoneNumber: $phoneNumber, password: $password }) {
            accessExpiresIn
            accessToken
            refreshExpiresIn
            refreshToken
          }
        }
      `,
      variables: {
        phoneNumber,
        password,
      },
    }).pipe(
      catchError((error: ApolloError) => {
        return throwError(() => new Error(error.message));
      }),
    );
  }

  public forgotPassword(phoneNumber: string): Observable<MutationResult<{ forgotPassword: ForgotPasswordResponse }>> {
    return this.apollo.mutate<{ forgotPassword: ForgotPasswordResponse }>({
      mutation: gql`
        mutation forgotPassword($phoneNumber: String!) {
          forgotPassword(data: { phoneNumber: $phoneNumber}) {
            success
          }
        }
      `,
      variables: {
        phoneNumber,
      },
    }).pipe(
      catchError((error: ApolloError) => throwError(() => new Error(error.message))),
    );
  }

  public changePassword(newPassword: string, oldPassword: string): Observable<MutationResult<{ updatePassword: ChangePasswordResponse }>> {
    return this.apollo.mutate<{ updatePassword: ChangePasswordResponse }>({
      mutation: gql`
        mutation updatePassword($newPassword: String!, $oldPassword: String!) {
          updatePassword(data: { newPassword: $newPassword, oldPassword: $oldPassword }) {
             success
          }
        }
      `,
      variables: {
        newPassword,
        oldPassword,
      },
    });
  }

  public getAdminData(): Observable<MutationResult<{ getAdminData: AdminData }>> {
    return this.apollo.query<{ getAdminData: AdminData }>({
      query: gql`
        query getAdminData {
          getAdminData {
            id
            isTemporaryPassword
            mobilePhone
            locale
            role {
              id
              name
            }
          }
        }
      `,
    }).pipe(
      tap((res) => {
        if (res && res.data) {
          this.store.dispatch(new SetAdminData(res.data.getAdminData));
        }
      })
    );
  }

  public logOut(): Observable<MutationResult<{ logout: LogoutResponse }>> {
    return this.apollo.mutate<{ logout: LogoutResponse }>({
      mutation: gql`
        mutation logout {
          logout {
            success
          }
        }
      `,
    });
  }

  public logOutFromInterceptor(): void {
    localStorage.removeItem(TokenEnum.accessToken);
    localStorage.removeItem(TokenEnum.refreshToken);
    void this.router.navigateByUrl('/auth');
  }

  startTimer(): void {
    this.timerValue$.next(this.timerValue);
    interval(this.intervalValue).pipe(
      takeUntil(this.stopTimer$),
      tap(() => {
        if (this.timerValue > 0) {
          this.timerValue--;
          this.timerValue$.next(this.timerValue);
        } else {
          this.stopTimer$.next();
        }
      }),
    ).subscribe();
  }

  stopTimer(): void {
    this.stopTimer$.next();
    this.timerValue$.next(null);
  }

  getTimerValue():Observable<number | null> {
    return this.timerValue$.asObservable();
  }
}
