/* eslint-disable */
import { Injectable } from '@angular/core';
import { State, Store, Selector, Action, StateContext } from '@ngxs/store';
import {
  CourierType,
  OrderAmountLimit,
  ProductDimension,
  TransformedVehicleType,
  TypeBaggage,
  VehicleType,
} from '@core/interfaces/reference-data';
import { tap } from 'rxjs';
import {
  ArchiveCourierType,
  ArchiveOrderAmountLimit,
  ArchiveTypeBaggage,
  ArchiveTypeVehicle,
  CreateCourierType,
  CreateOrderAmountLimit,
  CreateTypeBaggage,
  CreateTypeVehicle,
  GetListCourierType,
  GetListOrderAmountLimits,
  GetListProductDimension,
  GetListTypeBaggage,
  GetListTypeVehicles,
  UpdateCourierType,
  UpdateOrderAmountLimit,
  UpdateProductDimension,
  UpdateTypeBaggage,
  UpdateTypeVehicle,
} from '../action/reference.action';
import { ReferenceDataService } from '@core/services/reference-data.service';
import { transformVehicleType } from '@core/helper/type-vehicle-helper';

export class ReferencesStateModel {
  productDimension!: Array<ProductDimension>;
  typeVehicle!: Array<VehicleType>;
  orderAmountLimits!: Array<OrderAmountLimit>;
  courierType!: Array<CourierType>;
  typeBaggage!: Array<TypeBaggage>;
}

@State<ReferencesStateModel>({
  name: 'ReferencesState',
  defaults: {
    productDimension: [],
    typeVehicle: [],
    orderAmountLimits: [],
    courierType: [],
    typeBaggage: [],
  },
})
@Injectable()
export class ReferencesState {
  constructor(
    private store: Store,
    private referenceDataService: ReferenceDataService,
  ) {}

  @Selector()
  static getListProductDimension(state: ReferencesStateModel): ProductDimension[] {
    return state.productDimension;
  }

  @Selector()
  static getListTypeVehicles(state: ReferencesStateModel): VehicleType[] {
    return state.typeVehicle;
  }

  @Selector()
  static getListTypeVehiclesTransformered(state: ReferencesStateModel): TransformedVehicleType[] {
    return state.typeVehicle.map(vehicleType => transformVehicleType(vehicleType));
  }

  @Selector()
  static getListOrderAmountLimits(state: ReferencesStateModel): OrderAmountLimit[] {
    return state.orderAmountLimits;
  }

  @Selector()
  static getListCourierType(state: ReferencesStateModel): CourierType[] {
    return state.courierType;
  }

  @Selector()
  static getListTypeBaggage(state: ReferencesStateModel): TypeBaggage[] {
    return state.typeBaggage;
  }

  @Action(GetListProductDimension)
  public getListProductDimension(ctx: StateContext<ReferencesStateModel>) {
    return this.referenceDataService.getListProductDimension().pipe(
      tap((res) => {
        ctx.patchState({ productDimension: res.data.getListProductDimension });
      }),
    );
  }

  @Action(UpdateProductDimension)
  public updateProductDimension(
    ctx: StateContext<ReferencesStateModel>,
    { id, updateProductDimension, dialogRef}: UpdateProductDimension,
  ) {
    return this.referenceDataService.updateProductDimensionData(id, updateProductDimension).pipe(
      tap((res) => {
        if (res && res.data) {
          const updatedProductDimensionResponse = res.data.updateProductDimension;
          const currentState = ctx.getState();

          const index = currentState.productDimension.findIndex((pd) => pd.id === updatedProductDimensionResponse.id);

          if (index !== -1) {
            const updatedProductDimensions = [...currentState.productDimension];
            updatedProductDimensions[index] = updatedProductDimensionResponse;
            dialogRef.close()
            ctx.patchState({
              productDimension: updatedProductDimensions,
            });
          }
        }
      }),
    );
  }

  @Action(GetListTypeVehicles)
  public getListTypeVehicles(ctx: StateContext<ReferencesStateModel>) {
    return this.referenceDataService.getListVehicleType().pipe(
      tap((res) => {
        const originalData = res.data.getListVehicleType;

        ctx.patchState({
          typeVehicle: originalData,
        });
      }),
    );
  }

  @Action(CreateTypeVehicle)
  public createTypeVehicle(ctx: StateContext<ReferencesStateModel>, { vehicleType }: CreateTypeVehicle) {
    const currentState = ctx.getState();
    ctx.patchState({
      typeVehicle: [...currentState.typeVehicle, vehicleType],
    });
  }

  @Action(UpdateTypeVehicle)
  public updateTypeVehicle(ctx: StateContext<ReferencesStateModel>, { vehicleType }: UpdateTypeVehicle) {
    const currentState = ctx.getState();
    const typeVehicleIndex = currentState.typeVehicle.findIndex((tv) => tv.id === vehicleType.id);
    const updatedTypeVehicle = [...currentState.typeVehicle];
    updatedTypeVehicle[typeVehicleIndex] = vehicleType;

    ctx.patchState({
      typeVehicle: updatedTypeVehicle,
    });
  }

  @Action(ArchiveTypeVehicle)
  public archiveTypeVehicle(ctx: StateContext<ReferencesStateModel>, { id }: ArchiveTypeVehicle) {
    return this.referenceDataService.archiveVehicleType(id).pipe(
      tap((res) => {
        if (res && res.data) {
          const archivedVehicleId = res.data.archiveVehicleType.id;
          const currentState = ctx.getState();
          const typeVehicleMap = new Map(currentState.typeVehicle.map(tv => [tv.id, tv]));
          typeVehicleMap.delete(archivedVehicleId);

          ctx.patchState({
            typeVehicle: Array.from(typeVehicleMap.values()),
          });
        }
      }),
    );
  }

  @Action(GetListOrderAmountLimits)
  public getListOrderAmountLimits(ctx: StateContext<ReferencesStateModel>) {
    return this.referenceDataService.getListOrderAmountLimit().pipe(
      tap((res) => {
        const data = res.data.getListOrderAmountLimit;
        ctx.patchState({ orderAmountLimits: data });
      }),
    );
  }

  @Action(CreateOrderAmountLimit)
  public createOrderAmountLimit(
    ctx: StateContext<ReferencesStateModel>,
    { createOrderAmountLimit, dialogRef }: CreateOrderAmountLimit,
  ) {
    return this.referenceDataService.createOrderAmountLimit(createOrderAmountLimit).pipe(
      tap((res) => {
        if (res && res.data) {
          const createdLimit = res.data.createOrderAmountLimit;
          const currentState = ctx.getState();
          dialogRef.close()
          ctx.patchState({
            orderAmountLimits: [...currentState.orderAmountLimits, createdLimit],
          });
        }
      }),
    );
  }

  @Action(UpdateOrderAmountLimit)
  public updateOrderAmountLimit(
    ctx: StateContext<ReferencesStateModel>,
    { id, updateOrderAmountLimit, dialogRef }: UpdateOrderAmountLimit,
  ) {
    return this.referenceDataService.updateOrderAmountLimit(id, updateOrderAmountLimit).pipe(
      tap((res) => {
        if (res && res.data) {
          const updatedLimit = res.data.updateOrderAmountLimit;
          const currentState = ctx.getState();

          const updatedOrderAmountLimits = currentState.orderAmountLimits.map((limit) =>
            limit.id === updatedLimit.id ? updatedLimit : limit,
          );
          dialogRef.close()
          ctx.patchState({ orderAmountLimits: updatedOrderAmountLimits });
        }
      }),
    );
  }

  @Action(ArchiveOrderAmountLimit)
  public archiveOrderAmountLimit(ctx: StateContext<ReferencesStateModel>, { id }: ArchiveOrderAmountLimit) {
    return this.referenceDataService.archiveOrderAmountLimit(id).pipe(
      tap((res) => {
        if (res && res.data) {
          const archivedLimitId = res.data.archiveOrderAmountLimit.id;
          const currentState = ctx.getState();

          const orderAmountLimitsMap = new Map(currentState.orderAmountLimits.map(limit => [limit.id, limit]));
          orderAmountLimitsMap.delete(archivedLimitId);

          ctx.patchState({
            orderAmountLimits: Array.from(orderAmountLimitsMap.values()),
          });
        }
      }),
    );
  }

  @Action(GetListCourierType)
  public getListCourierType(ctx: StateContext<ReferencesStateModel>) {
    return this.referenceDataService.getListCourierType().pipe(
      tap((res) => {
        ctx.patchState({ courierType: res.data.getListCourierType });
      }),
    );
  }

  @Action(CreateCourierType)
  public createCourierType(ctx: StateContext<ReferencesStateModel>, { createCourierType,dialogRef }: CreateCourierType) {
    return this.referenceDataService.createCourierType(createCourierType).pipe(
      tap((res) => {
        if (res && res.data) {
          const createdCourierType = res.data.createCourierType;
          const currentState = ctx.getState();
          dialogRef.close();
          ctx.patchState({
            courierType: [...currentState.courierType, createdCourierType],
          });
        }
      }),
    );
  }

  @Action(UpdateCourierType)
  public updateCourierType(ctx: StateContext<ReferencesStateModel>, { id, updateCourierType, dialogRef }: UpdateCourierType) {
    return this.referenceDataService.updateCourierType(id, updateCourierType).pipe(
      tap((res) => {
        if (res && res.data) {
          const updatedcourierType = res.data.updateCourierType;
          const currentState = ctx.getState();

          const updatedCourierType = currentState.courierType.map((limit) =>
            limit.id === updatedcourierType.id ? updatedcourierType : limit,
          );
          dialogRef.close();
          ctx.patchState({ courierType: updatedCourierType });
        }
      }),
    );
  }

  @Action(ArchiveCourierType)
  public archiveCourierType(ctx: StateContext<ReferencesStateModel>, { id }: ArchiveOrderAmountLimit) {
    return this.referenceDataService.archiveCourierType(id).pipe(
      tap((res) => {
        if (res && res.data) {
          const archivedCourierTypeId = res.data.archiveCourierType.id;
          const currentState = ctx.getState();

          const courierTypeMap = new Map(currentState.courierType.map(ct => [ct.id, ct]));
          courierTypeMap.delete(archivedCourierTypeId);

          ctx.patchState({
            courierType: Array.from(courierTypeMap.values()),
          });
        }
      }),
    );
  }

  @Action(GetListTypeBaggage)
  public getListTypeBaggage(ctx: StateContext<ReferencesStateModel>) {
    return this.referenceDataService.getListCourierEquipmentType().pipe(
      tap((res) => {
        ctx.patchState({ typeBaggage: res.data.getListCourierEquipmentType });
      }),
    );
  }

  @Action(CreateTypeBaggage)
  public createTypeBaggage(ctx: StateContext<ReferencesStateModel>, { baggageType }: CreateTypeBaggage) {
    const currentState = ctx.getState();
    ctx.patchState({
      typeBaggage: [...currentState.typeBaggage, baggageType],
    });
  }

  @Action(UpdateTypeBaggage)
  public updateTypeBaggage(ctx: StateContext<ReferencesStateModel>, { baggageType }: UpdateTypeBaggage) {
    const currentState = ctx.getState();

    const updatedTypeBaggage = currentState.typeBaggage.map((baggage) =>
      baggage.id === baggageType.id ? baggageType : baggage,
    );
    ctx.patchState({ typeBaggage: updatedTypeBaggage });
  }

  @Action(ArchiveTypeBaggage)
  public archiveTypeBaggage(ctx: StateContext<ReferencesStateModel>, { id }: ArchiveTypeBaggage) {
    return this.referenceDataService.archiveTypeBaggage(id).pipe(
      tap((res) => {
        if (res && res.data) {
          const archivedTypeBaggageId = res.data.archiveCourierEquipmentType.id;
          const currentState = ctx.getState();

          const typeBaggageMap = new Map(currentState.typeBaggage.map(tb => [tb.id, tb]));
          typeBaggageMap.delete(archivedTypeBaggageId);

          ctx.patchState({
            typeBaggage: Array.from(typeBaggageMap.values()),
          });
        }
      }),
    );
  }
}
