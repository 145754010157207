/* eslint-disable */

import { Injectable } from '@angular/core';
import { CourierScheduleService } from '@core/services/courier-shedule.service';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  AddCourierSchedule,
  ArchiveCourierSchedules,
  GetCourierSchedules,
  UpdateCourierSchedule,
} from '../action/courier-work-schedule.action';
import { CourierScheduleData, CourierSheduleResponse } from '@core/interfaces/courier-shedule.interface';
import { getGroupedSchedules, getUniqueDates, prepareScheduleMatrix } from '@core/helper/courier-work-schedule-helper';

export class CourierSchedulesStateModel {
  schedules!: CourierSheduleResponse[];
  groupedSchedules!: CourierScheduleData[];
  uniqueDates!: any[];
  scheduleMatrix!: any[];
}

@State<CourierSchedulesStateModel>({
  name: 'CourierSchedulesState',
  defaults: {
    schedules: [],
    groupedSchedules: [],
    uniqueDates: [],
    scheduleMatrix: [],
  },
})
@Injectable()
export class CourierSchedulesState {
  constructor(private courierScheduleService: CourierScheduleService) {}

  @Selector()
  static schedules(state: CourierSchedulesStateModel): CourierSheduleResponse[] {
    return state.schedules;
  }

  @Selector()
  static groupedSchedules(state: CourierSchedulesStateModel): CourierScheduleData[] {
    return state.groupedSchedules;
  }

  @Selector()
  static getUniqueDates(state: CourierSchedulesStateModel): string[] {
    return state.uniqueDates;
  }

  @Selector()
  static getScheduleMatrix(state: CourierSchedulesStateModel): CourierSheduleResponse[][][] {
    return state.scheduleMatrix;
  }

  @Action(GetCourierSchedules)
  public getCourierSchedules(
    ctx: StateContext<CourierSchedulesStateModel>,
    { courierWorkSchedulesFilter }: GetCourierSchedules,
  ) {
    return this.courierScheduleService.getCourierSchedule(courierWorkSchedulesFilter).pipe(
      tap((res) => {
        const schedules = res.data!.getListCourierWorkSchedule;
        const groupedSchedules = getGroupedSchedules(schedules);
        const uniqueDates = getUniqueDates(groupedSchedules);
        const scheduleMatrix = prepareScheduleMatrix(groupedSchedules, uniqueDates);

        ctx.patchState({
          schedules: schedules,
          groupedSchedules: groupedSchedules,
          uniqueDates: uniqueDates,
          scheduleMatrix: scheduleMatrix,
        });
      }),
    );
  }

  @Action(AddCourierSchedule)
  public addCourierSchedule(
    ctx: StateContext<CourierSchedulesStateModel>,
    { courierScheduleData }: AddCourierSchedule,
  ) {
    const currentState = ctx.getState();

    const updatedSchedules = [...currentState.schedules, courierScheduleData];

    ctx.patchState({
      schedules: updatedSchedules,
    });
  }

  @Action(UpdateCourierSchedule)
  public updateCourierSchedule(
    ctx: StateContext<CourierSchedulesStateModel>,
    { courierScheduleData }: UpdateCourierSchedule,
  ) {
    const currentState = ctx.getState();

    const updatedSchedules = [...currentState.schedules];

    const index = updatedSchedules.findIndex((s) => s.id === courierScheduleData.id);

    if (index !== -1) {
      updatedSchedules[index] = { ...updatedSchedules[index], ...courierScheduleData };
    }

    ctx.patchState({
      schedules: updatedSchedules,
    });
  }

  @Action(ArchiveCourierSchedules)
  public archiveCourierSchedule(
    ctx: StateContext<CourierSchedulesStateModel>,
    { id, archivedAt }: ArchiveCourierSchedules,
  ) {
    const currentState = ctx.getState();

    const updatedSchedules = currentState.schedules.map((schedule) => {
      if (schedule.id === id) {
        return { ...schedule, archivedAt, canArchive: false, canUpdate: false };
      }
      return schedule;
    });

    ctx.patchState({
      schedules: updatedSchedules,
    });

    const schedules = ctx.getState().schedules;
    const groupedSchedules = getGroupedSchedules(schedules);
    const uniqueDates = getUniqueDates(groupedSchedules);
    const scheduleMatrix = prepareScheduleMatrix(groupedSchedules, uniqueDates);

    ctx.patchState({
      schedules: schedules,
      groupedSchedules: groupedSchedules,
      uniqueDates: uniqueDates,
      scheduleMatrix: scheduleMatrix,
    });
  }
}
