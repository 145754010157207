import { Component, OnInit } from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { ApiAuthService } from '@core/services/api-auth.service';
import { ChangePasswordResponse } from '@core/interfaces/auth.interface';
import { MutationResult } from 'apollo-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PasswordVisibilityDirective } from '../password-visibility.directive';
import { passwordMatchValidator } from '../password-match-validator';
import { phoneNumberValidator } from '../phone-number-validator';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordVisibilityDirective,
    RouterModule,
    TranslateModule,
  ],
})
export class ChangePasswordComponent implements OnInit {
  public loginForm!: FormGroup;

  errorMessage: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly apiAuthService: ApiAuthService,
  ) {

  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.loginForm = this.fb.group({
      password: new FormControl('', {
        validators: [Validators.required, Validators.minLength(4),],
      }),
      confirmPassword: new FormControl('', {
        validators: [Validators.required, Validators.minLength(4)],
      }),
    });
  }

  public onSubmitForm(): void {
    this.loginForm.setValidators(passwordMatchValidator);
    this.loginForm.updateValueAndValidity();
    
    const { confirmPassword } = this.loginForm.value;
    const oldPassword = this.apiAuthService.oldPasswordData;
    const confirmPasswordString = confirmPassword.toString();

    this.apiAuthService.changePassword(confirmPasswordString, oldPassword).pipe(untilDestroyed(this)).subscribe((res: MutationResult<{ updatePassword: ChangePasswordResponse }>) => {
      if (res.data && res.data.updatePassword.success) {
        void this.router.navigateByUrl('/reference-data')
      }
    });
  }
}
